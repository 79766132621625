// Add Google Fonts here, if applicable
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap');
@import url("https://use.typekit.net/tgp7iia.css");

// GLOBALS
body {
    @apply font-body text-base h-full w-full overflow-x-hidden;
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
    //text-rendering: optimizeLegibility;
}

// HEADINGS - general rule to make all 20% smaller for mobile if no mobile designs, adjust as needed
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    @apply w-full font-header font-light text-brand-grey-700 block;
}

h1,
.h1 {
    @apply text-3xl md:text-4xl xl:text-6xl;
}

h2,
.h2 {
    @apply text-2xl sm:text-3xl xl:text-5xl;
}

h3,
.h3 {
    @apply font-body text-xl md:text-2xl xl:text-header xl:leading-tight;
}

h4,
.h4 {
    @apply text-xl md:text-2xl xl:text-4xl;
}

h5,
.h5 {
    @apply font-normal uppercase font-body text-brand-grey-700 text-xl tracking-wider mb-4;
}

h6,
.h6 {
    @apply font-normal uppercase font-body text-brand-grey-700 text-lg tracking-wider mb-4;
}

// PARAGRAPGHS

.text-small,
.text-small p {
    @apply text-xs;

    @screen md {
        @apply text-sm;
    }
}

.text-medium,
.text-medium p {
    @apply text-base;

    @screen md {
        @apply text-lg;
    }
}

.text-large,
.text-large p {
    @apply text-lg;

    @screen md {
        @apply text-xl;
    }
}

.trimLast {
    *:last-child {
        @apply mb-0;
    }
}

// LINKS
a {
    @apply font-body text-brand-blue-100 hover:text-brand-blue-400 transition-colors;
}

strong {
    @apply font-bold;
}

// LISTS
.richText {
    ul,
    ol {
        @apply mb-4;

        li {
            @apply text-sm list-inside py-1;
        }

        @screen md {
            @apply mb-8;

            li {
                @apply text-base;
            }
        }
    }

    ul {
        li {
            @apply list-disc;
        }
    }

    ol {
        li {
            @apply list-decimal;
        }
    }
}

.rich-text {
    @apply prose prose md:prose-2xl max-w-none;
}

// BUTTONS
.button {
    @apply font-body text-sm px-5 py-4 border leading-none uppercase inline-block tracking-wider focus:ring-brand-green-100 focus:outline-none focus:ring focus:border-transparent focus:text-brand-green-100;
}

.button-lg {
    @apply text-base py-5 px-8;
}

.button-dark {
    @apply border-brand-grey-400 text-brand-grey-1200 hover:text-brand-grey-600 hover:border-brand-grey-600;
}

.button-black {
    @apply border-black text-black hover:text-brand-green-100 hover:border-brand-green-100;
}

.button-white {
    @apply border-white text-white hover:text-brand-green-100 hover:border-brand-green-100;
}

.lata {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        @apply text-brand-blue-800;
    }

    .rich-text {
        color: #4e4f4c;
    }

    a {
        @apply text-brand-green-100 hover:text-brand-green-400;
    }

    .button-dark {
        @apply text-brand-grey-900 border-brand-grey-400 hover:text-brand-green-100 hover:border-brand-green-100;
    }

    .button-light {
        @apply border-brand-green-100 text-brand-green-100 hover:text-brand-green-400 hover:border-brand-green-400;
    }

    .button-black {
        @apply border-black text-brand-blue-800 hover:text-brand-green-100 hover:border-brand-green-100;
    }

    .button-grey {
        @apply text-brand-grey-800 border-brand-grey-400 hover:text-brand-green-100 hover:border-brand-green-100;
    }

    .button-more {
        @apply text-brand-blue-1000 border-brand-blue-1000;
    }
}
