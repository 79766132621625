@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap");
@import url("https://use.typekit.net/tgp7iia.css");
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
}
.glide__arrow:focus {
  outline: none;
}
.glide__arrow:hover {
  border-color: white;
}
.glide__arrow--left {
  left: 2em;
}
.glide__arrow--right {
  right: 2em;
}
.glide__arrow--disabled {
  opacity: 0.33;
}
.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%);
}
.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em;
}
.glide__bullet:focus {
  outline: none;
}
.glide__bullet:hover, .glide__bullet:focus {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.5);
}
.glide__bullet--active {
  background-color: white;
}
.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

/* ==========================================================================
   Tailwind
   ========================================================================== */
@tailwind base;
@tailwind components;
/* ==========================================================================
   Base
   ========================================================================== */
*, *::after, *::before {
  box-sizing: border-box;
}

a, .btn, .button, img, svg {
  @apply transition duration-150 ease-in-out;
}

.layout {
  @apply flex flex-wrap content-center justify-center -mx-5;
}
.layout-item {
  @apply align-middle px-5;
}

.aspect-16x9 {
  @apply relative;
  padding-bottom: 56.25%;
}
.aspect-16x9 iframe, .aspect-16x9 embed, .aspect-16x9 object, .aspect-16x9 video {
  @apply absolute inset-0 w-full h-full;
}

body {
  @apply font-body text-base h-full w-full overflow-x-hidden;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  @apply w-full font-header font-light text-brand-grey-700 block;
}

h1,
.h1 {
  @apply text-3xl md:text-4xl xl:text-6xl;
}

h2,
.h2 {
  @apply text-2xl sm:text-3xl xl:text-5xl;
}

h3,
.h3 {
  @apply font-body text-xl md:text-2xl xl:text-header xl:leading-tight;
}

h4,
.h4 {
  @apply text-xl md:text-2xl xl:text-4xl;
}

h5,
.h5 {
  @apply font-normal uppercase font-body text-brand-grey-700 text-xl tracking-wider mb-4;
}

h6,
.h6 {
  @apply font-normal uppercase font-body text-brand-grey-700 text-lg tracking-wider mb-4;
}

.text-small,
.text-small p {
  @apply text-xs;
}
@screen md {
  .text-small,
.text-small p {
    @apply text-sm;
  }
}

.text-medium,
.text-medium p {
  @apply text-base;
}
@screen md {
  .text-medium,
.text-medium p {
    @apply text-lg;
  }
}

.text-large,
.text-large p {
  @apply text-lg;
}
@screen md {
  .text-large,
.text-large p {
    @apply text-xl;
  }
}

.trimLast *:last-child {
  @apply mb-0;
}

a {
  @apply font-body text-brand-blue-100 hover:text-brand-blue-400 transition-colors;
}

strong {
  @apply font-bold;
}

.richText ul,
.richText ol {
  @apply mb-4;
}
.richText ul li,
.richText ol li {
  @apply text-sm list-inside py-1;
}
@screen md {
  .richText ul,
.richText ol {
    @apply mb-8;
  }
  .richText ul li,
.richText ol li {
    @apply text-base;
  }
}
.richText ul li {
  @apply list-disc;
}
.richText ol li {
  @apply list-decimal;
}

.rich-text {
  @apply prose prose md:prose-2xl max-w-none;
}

.button {
  @apply font-body text-sm px-5 py-4 border leading-none uppercase inline-block tracking-wider focus:ring-brand-green-100 focus:outline-none focus:ring focus:border-transparent focus:text-brand-green-100;
}

.button-lg {
  @apply text-base py-5 px-8;
}

.button-dark {
  @apply border-brand-grey-400 text-brand-grey-1200 hover:text-brand-grey-600 hover:border-brand-grey-600;
}

.button-black {
  @apply border-black text-black hover:text-brand-green-100 hover:border-brand-green-100;
}

.button-white {
  @apply border-white text-white hover:text-brand-green-100 hover:border-brand-green-100;
}

.lata h1,
.lata .h1,
.lata h2,
.lata .h2,
.lata h3,
.lata .h3,
.lata h4,
.lata .h4,
.lata h5,
.lata .h5,
.lata h6,
.lata .h6 {
  @apply text-brand-blue-800;
}
.lata .rich-text {
  color: #4e4f4c;
}
.lata a {
  @apply text-brand-green-100 hover:text-brand-green-400;
}
.lata .button-dark {
  @apply text-brand-grey-900 border-brand-grey-400 hover:text-brand-green-100 hover:border-brand-green-100;
}
.lata .button-light {
  @apply border-brand-green-100 text-brand-green-100 hover:text-brand-green-400 hover:border-brand-green-400;
}
.lata .button-black {
  @apply border-black text-brand-blue-800 hover:text-brand-green-100 hover:border-brand-green-100;
}
.lata .button-grey {
  @apply text-brand-grey-800 border-brand-grey-400 hover:text-brand-green-100 hover:border-brand-green-100;
}
.lata .button-more {
  @apply text-brand-blue-1000 border-brand-blue-1000;
}

/* ==========================================================================
   Components
   ========================================================================== */
.forms label {
  @apply font-body flex items-center font-body text-sm sm:text-base text-brand-grey-1200 uppercase font-light tracking-widest;
}
.forms input, .forms textarea {
  @apply outline-none;
}
.forms .text {
  @apply w-full bg-white border border-brand-grey-400 px-5 py-3 placeholder-brand-grey-400 text-base md:text-lg;
}
.forms .parsley-error {
  @apply border border-red-400;
}
.forms .parsley-errors-list {
  @apply mt-3 text-red-400;
}

@tailwind utilities;
.glide__arrow {
  @apply shadow-none border-0 border-transparent;
}

.header {
  @apply bg-brand-grey-700;
}
.header-link {
  @apply font-body text-white hover:text-brand-blue-200 opacity-80 uppercase tracking-wider;
}
.header-active {
  @apply text-brand-blue-200;
}

.mobile-menu {
  @apply bg-brand-blue-500;
}

.footer {
  @apply bg-brand-grey-1300;
}
.footer-link {
  @apply bg-brand-blue-300 text-white h-12 w-12 flex items-center justify-center rounded-full;
}

.hero {
  @apply bg-brand-yellow-300 bg-center bg-cover bg-no-repeat w-full py-8 lg:py-0 h-36 md:h-48 lg:h-72 flex items-end;
}

.home-hero-title {
  @apply font-header font-thin text-brand-grey-1000 text-lg md:text-2xl xl:text-3xl leading-snug sm:leading-snug md:leading-snug xl:leading-snug text-center xl:text-left;
}

.events-slider-bg {
  @apply bg-brand-grey-100;
}

.home-news {
  @apply bg-brand-green-300;
}
.home-news-subtitle {
  @apply uppercase font-body text-brand-blue-200;
}
.home-news-title {
  @apply text-white mt-3;
}
.home-news-list {
  @apply bg-brand-green-400;
}
.home-news-list-date {
  @apply uppercase font-body text-white block tracking-widest font-light text-sm;
}
.home-news-list-link {
  @apply font-body text-brand-blue-300 hover:text-white sm:text-lg xl:text-2xl pt-1 block font-light;
}
.home-news-icon-bg {
  @apply bg-brand-blue-200;
}
.home-news-card {
  @apply bg-brand-blue-300;
}
.home-news-card-date {
  @apply uppercase font-medium font-body text-brand-green-400 opacity-80 block text-sm tracking-wider;
}
.home-news-card-link {
  @apply font-body text-white text-lg sm:text-xl xl:text-2xl pt-1 block font-light;
}

.home-articles {
  @apply bg-brand-grey-100;
}
.home-articles-date {
  @apply uppercase font-medium font-body text-brand-grey-600 block text-sm tracking-widest;
}
.home-articles-link {
  @apply font-body text-brand-blue-300 hover:text-brand-blue-400 pt-4 block text-2xl;
}

.contact-cta {
  @apply bg-brand-grey-700;
}
.contact-cta-title {
  @apply font-header text-brand-yellow-300 text-2xl md:text-3xl font-light;
}
.contact-cta .button-light {
  @apply text-white border-white transition-colors hover:text-brand-yellow-300 hover:border-brand-yellow-300;
}
.contact-cta .rich-text {
  @apply text-white opacity-80 pt-5 pb-6;
}

.bio-title {
  @apply text-brand-blue-400 xl:leading-none pb-3 lg:hidden border-b mb-8;
}

.bio-title-2 {
  @apply font-header font-light text-brand-blue-400 text-2xl sm:text-3xl xl:text-5xl xl:leading-none mb-0 pb-3;
}

.bio-box {
  @apply bg-brand-grey-100;
}
.bio-box-border {
  @apply border-gray-300;
}

.contact-title {
  @apply text-brand-blue-400;
}

.contact-box {
  @apply bg-brand-grey-100;
}

.alt-bg {
  @apply bg-brand-grey-100;
}

.lata .header {
  @apply bg-brand-blue-800;
}
.lata .header a {
  @apply text-white;
}
.lata .header a:hover {
  @apply text-brand-yellow-200;
}
.lata .mobile-menu {
  @apply bg-brand-blue-800;
}
.lata .mobile-menu a {
  @apply text-white;
}
.lata .footer {
  @apply bg-brand-grey-1500;
}
.lata .footer-link {
  @apply bg-brand-blue-700 text-white;
}
.lata .home-hero-title {
  @apply font-header font-thin text-brand-blue-800 text-lg md:text-2xl xl:text-3xl leading-snug sm:leading-snug md:leading-snug xl:leading-snug text-center xl:text-left;
}
.lata .events-slider-bg {
  @apply bg-brand-grey-200;
}
.lata .events-slider h3 a {
  @apply text-brand-blue-1000;
}
.lata .events-slider h3 a:hover {
  @apply opacity-70;
}
.lata .home-news {
  @apply bg-brand-yellow-400;
}
.lata .home-news-subtitle {
  @apply text-brand-blue-1000;
}
.lata .home-news-title {
  @apply text-brand-blue-800;
}
.lata .home-news-list {
  @apply bg-brand-yellow-500;
}
.lata .home-news-list-date {
  @apply text-brand-grey-700 font-medium;
}
.lata .home-news-list-link {
  @apply text-brand-blue-1000 font-normal;
}
.lata .home-news-icon-bg {
  @apply bg-brand-yellow-600;
}
.lata .home-news-card {
  @apply bg-brand-blue-900;
}
.lata .home-news-card-date {
  @apply text-brand-blue-800;
}
.lata .home-news-card-link {
  @apply text-white;
}
.lata .home-articles {
  @apply bg-brand-grey-1600;
}
.lata .home-articles-link {
  @apply text-brand-green-100;
}
.lata .contact-cta {
  @apply bg-brand-blue-600;
}
.lata .contact-cta-title {
  @apply text-brand-yellow-200;
}
.lata .contact-cta .button-light {
  @apply text-white border-white transition-colors hover:text-brand-yellow-100 hover:border-brand-yellow-100;
}
.lata .home-contact .contact-cta {
  @apply bg-brand-green-400;
}
.lata .bio-title,
.lata .bio-title-2 {
  @apply text-brand-blue-800;
}
.lata .bio-box {
  @apply bg-brand-grey-200;
}
.lata .bio-box-border {
  @apply border-brand-grey-500;
}
.lata .contact-title {
  @apply text-brand-blue-800;
}
.lata .contact-box {
  @apply bg-brand-grey-1600;
}
.lata .alt-bg {
  @apply bg-brand-grey-200;
}
.lata .hero {
  @apply bg-brand-blue-900;
}