*, *::after, *::before {
  box-sizing: border-box;
}

a, .btn, .button, img, svg {
	@apply transition duration-150 ease-in-out;
}

// Basic flex layout
.layout {
	@apply flex flex-wrap content-center justify-center -mx-5;

	&-item {
		@apply align-middle px-5;
	}
}

// Responsive videos
.aspect-16x9 {
	@apply relative;
  padding-bottom: 56.25%;

  iframe, embed, object, video {
	  @apply absolute inset-0 w-full h-full;
  }
}
